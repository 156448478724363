import { put } from 'redux-saga/effects';
import { takeLatest } from '@redux-saga/core/effects';
import { apis } from "../../../constants";
import { doFetchMedSupplies, doFetchSuppliesFromStorage, fetchMedSuppliesSuccess, fetchSuppliesFromStorageSuccess, setSupplyLoading } from 'ducks/slices/ListData/Supply.slice';
import { common_post } from 'helpers';

export function* watchDoSupplies() {
    yield takeLatest(doFetchSuppliesFromStorage, handleFetchSuppliesFromStorage)
    yield takeLatest(doFetchMedSupplies, handleFetchMedSupplies)
}

function* handleFetchSuppliesFromStorage(action) {
    const { search_string = "", KHO_VAT_TU_ID, setData } = action.payload
    try {
        yield put(setSupplyLoading(true));
        const res = yield common_post(apis.dm_lay_ds_vat_tu_trong_kho, {
            page: 1,
            limit: 20,
            search_string,
            KHO_VAT_TU_ID,
        })
        if (res) {
            if (res.status === 'OK') {
                setData
                    ? setData(
                        res.result?.map(item => ({ ...item, KHO_VAT_TU_ID }))
                    )
                    : yield put(fetchSuppliesFromStorageSuccess(res))
            }
        }
    } catch (err) {
        console.log(err.message)
    } finally {
        yield put(setSupplyLoading(false));
    }
}

function* handleFetchMedSupplies(action) {
    const { search_string = "", setData, lotMode } = action.payload
    try {
        yield put(setSupplyLoading(true));
        const res = yield common_post(
            lotMode ? apis.layDsVatTuBan : apis.dm_lay_ds_thuoc_vat_tu,
            {
                page: 1,
                limit: 40,
                search_string,
            }
        )
        if (res) {
            if (res.status === 'OK') {
                setData
                    ? setData(
                        res.result?.map(i => ({
                            ...i,
                            [lotMode ? "ID_VT" : "VAT_TU_TRONG_KHO_ID"]: i.THUOC_ID ? `THUOC_${i.THUOC_ID}` : `VAT_TU_${i.VAT_TU_ID}`,
                        }))
                    )
                    : yield put(fetchMedSuppliesSuccess(res))
            }
        }
    } catch (err) {
        console.log(err.message)
    } finally {
        yield put(setSupplyLoading(false));
    }
}