/* eslint-disable require-yield */
import { notification } from "antd";
import {
  detailPackageSubscription,
  detailPackageSubscriptionSuccess,
  editRegistration,
  editRegistrationSuccess,
  getListServicePack,
  listServicePackSuccess,
  packageSubscriptionList,
  packageSubscriptionListSuccess,
  register,
  registerSuccess,
  setCommonLoadingState,
  subcribeBundles
} from "ducks/slices/GoiDichVu/DKGoiDichVu/DKGoiDichVuSlice";
import { HLog, common_post } from "helpers";
import moment from "moment";
import { call, put, putResolve, select, takeLatest } from "redux-saga/effects";
import { apis } from "../../../constants";
import i18n, { languageKeys } from "../../../i18n";
import { trangThaiGoiDv } from "pages/QuanLyGoiDichVu/constants";
import { getServicePackageUsage } from "ducks/slices/GoiDichVu/ThongTinSDGoiDv/serivcePackageUsage.slice";
import { formatPatientData } from "pages/QuanLyTiepDon/hooksTiepDon";
import { setFormLoading } from "ducks/slices/loadingSlice";
import { TiepDonActions } from "ducks/slices/QlTiepdon/TiepDon.slice";
import _ from "lodash";

export function* watchSignUpForAServicePack() {
  yield takeLatest(getListServicePack, handleGetlistServicePackage);
  yield takeLatest(register, handleRegister);
  yield takeLatest(editRegistration, handleEditRegistration);
  yield takeLatest(packageSubscriptionList, handlePackageSubscriptionList);
  yield takeLatest(detailPackageSubscription, handleDetailPackageSubscription);
  yield takeLatest(subcribeBundles, handleSubcribeBundles);
}

export function* handleDetailPackageSubscription({ payload }) {
  const userProfile = yield select((state) => state.auth.user);
  yield put(setFormLoading(true));
  try {
    let res = yield common_post(apis.chi_tiet_dk_goi_dich_vu, {
      ID: payload,
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
    });

    if (res.status === "OK") {
      yield put(detailPackageSubscriptionSuccess({ data: res.result[0] }));
    }
  } catch (error) {
    HLog(error);
  } finally {
    yield put(setFormLoading(false));
  }
}

export function* handleGetlistServicePackage({ payload }) {
  const userProfile = yield select((state) => state.auth.user);

  try {
    let res = yield common_post(apis.lay_ds_goi_dv, {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      TU_NGAY: "20101010",
      DEN_NGAY: moment(new Date(), "YYYYMMDD").add("years", 10).format("YYYYMMDD"),
      ARR_TRANG_THAI: [trangThaiGoiDv.hoat_dong, trangThaiGoiDv.sap_het_han],
      ARR_LOAI_GOI_ID: [],
      search_string: payload || null,
      page: 1,
      limit: 100,
      NHOM_GOI: ['DICH_VU']
    });
    if (res.status === "OK") {
      yield put(listServicePackSuccess(res.result));
      return
    }
    HLog(res);
    notification.error({
      placement: "bottomLeft",
      message: i18n.t(languageKeys.lay_thong_tin_that_bai),
    });

  } catch (error) {
    HLog(error);
    notification.error({
      placement: "bottomLeft",
      message: i18n.t(languageKeys.lay_thong_tin_that_bai),
    });
  }
}

export function* registerApi(payload) {
  // console.log(`payload`, payload);
  // return;
  try {
    yield putResolve(setCommonLoadingState(true));
    let bn_rs;
    if (payload.isUpdate)
      bn_rs = yield common_post(apis.tiep_don_sua_thong_tin_benh_nhan, { ...payload, ID: payload.BENH_NHAN_ID });

    let res = yield common_post(apis.dk_goi_dv, payload);
    if (res.status === "OK") {
      notification.success({
        placement: "bottomLeft",
        message: i18n.t(languageKeys.dk_goi_thanh_cong),
      });
      yield put(registerSuccess());
      yield put(getServicePackageUsage({
        page: 1
      }))
      let { callbackPatient } = payload;

      callbackPatient && callbackPatient(bn_rs);
    } else {
      if (res.message === "Bệnh nhân đã đăng ký gói dịch vụ này") {
        notification.error({
          placement: "bottomLeft",
          message: i18n.t(languageKeys.bn_da_dang_ky_goi_dv_nay),
        });
        return;
      }
      notification.error({
        placement: "bottomLeft",
        message: i18n.t(languageKeys.tao_moi_that_bai),
      });
    }
  } catch (error) {
    HLog(error);
  } finally {
    yield putResolve(setCommonLoadingState(false));
  };
}

function* handleCheckDuplicateInfo(payloadData, form, duplicateInfoRef) {
  const checkResult = yield common_post(apis.checkTrungTTKH, {
    HO_TEN: payloadData?.TEN,
    NGAY_SINH: payloadData?.NGAY_SINH?.slice(-4),
    SO_DIEN_THOAI: payloadData?.SO_DIEN_THOAI
  })
  if (checkResult?.status === 'OK') {
    if (checkResult?.data?.length) {
      const data = checkResult.data[0];
      if (!payloadData.BENH_NHAN_ID) {
        duplicateInfoRef?.current?.open({ ...data, BENH_NHAN_ID: data?.ID })
        return true;
      }
      if (payloadData.BENH_NHAN_ID && data?.ID !== payloadData.BENH_NHAN_ID) {
        form.setFields([
          {
            name: 'TEN',
            errors: [i18n.t(languageKeys.trung_thong_tin)]
          },
          {
            name: 'SO_DIEN_THOAI',
            errors: [i18n.t(languageKeys.trung_thong_tin)]
          },
          {
            name: 'NGAY_SINH',
            errors: [i18n.t(languageKeys.trung_thong_tin)]
          },
        ])
        notification.error({
          message: i18n.t(languageKeys.trung_thong_tin),
          placement: 'bottomLeft'
        })
        return true;
      }
    }
  }
}

export function* handleRegister({ payload }) {

  const userProfile = yield select((state) => state.auth.user);
  const { callback = () => {}, callbackPatient = () => {}, form, duplicateInfoRef, ...pushedPayload } = payload;
  let payloadRq = {
    ...pushedPayload,
    partner_code: userProfile.partner_code,
    BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
    NHANSU_ID: userProfile.NHANSU_ID,
  };

  if (payloadRq?.TEN) {
    let arrayTen = payloadRq?.TEN?.trim()?.split(' ') || [" "]
    payloadRq.HO = arrayTen?.[0] || ""
  }

  function parseAndAssignField(payloadRq, field) {
    if (payloadRq[field]?.includes("{")) {
      HLog(`obj-------isPhuongXa`, JSON.parse(payloadRq[field]));
      return {
        ...payloadRq,
        ...JSON.parse(payloadRq[field]),
      };
    } else {
      delete payloadRq[field];
      return payloadRq;
    }
  }

  payloadRq = parseAndAssignField(payloadRq, "TEN_PHUONG_XA");
  payloadRq = parseAndAssignField(payloadRq, "TEN_QUAN_HUYEN");
  payloadRq = parseAndAssignField(payloadRq, "TEN_TINH_THANH");

  yield put(setFormLoading(true));
  // return;

  if (typeof payloadRq.BENH_NHAN_ID === "undefined") {
    const isDup = yield call(handleCheckDuplicateInfo, payloadRq, form, duplicateInfoRef)
    if (isDup) return false;
    try {
      const resCreateBenhNhan = yield common_post(apis.tiep_don_tao_benh_nhan, {
        ...payloadRq,
        ID: null,
        NGAY_SINH: payloadRq.NGAY_SINH.length === 8 ? moment(payloadRq.NGAY_SINH, "DDMMYYYY").format("YYYYMMDD") : payloadRq.NGAY_SINH,
      });
      if (resCreateBenhNhan.status === "OK") {
        yield registerApi({
          ...payloadRq,
          BENH_NHAN_ID: resCreateBenhNhan.ID,
          NGAY_SINH: payloadRq.NGAY_SINH.length === 8 ? moment(payloadRq.NGAY_SINH, "DDMMYYYY").format("YYYYMMDD") : payloadRq.NGAY_SINH,
        });
        callback({ BENH_NHAN_ID: resCreateBenhNhan.ID, result: resCreateBenhNhan?.result, GOI_DV_ID: payloadRq.GOI_DV_ID ? payloadRq.GOI_DV_ID : payloadRq.ID });
      } else {
        notification.error({
          placement: "bottomLeft",
          message: i18n.t(languageKeys.noti_tao_moi_benh_nhan_that_bai),
        });
      }
    } catch (error) {
      HLog(error);
      notification.error({
        placement: "bottomLeft",
        message: i18n.t(languageKeys.noti_tao_moi_benh_nhan_that_bai),
      });
    }
  } else {
    console.log("payloadRq.NGAY_SINH", payloadRq.NGAY_SINH);
    yield registerApi({
      callbackPatient,
      isUpdate: true, ...payloadRq, NGAY_SINH: payloadRq.NGAY_SINH.length === 8 ? moment(payloadRq.NGAY_SINH, "DDMMYYYY").format("YYYYMMDD") : payloadRq.NGAY_SINH
    });
    callback({ GOI_DV_ID: payloadRq.GOI_DV_ID ? payloadRq.GOI_DV_ID : payloadRq.ID });
  }

  yield put(setFormLoading(false));
}

export function* handleEditRegistration({ payload }) {
  HLog(`handleEditRegistration33333333`, payload);
  // return
  const { callback = () => {}, form, duplicateInfoRef, ...pushedPayload } = payload;
  const userProfile = yield select((state) => state.auth.user);

  let payloadRq = {
    ...pushedPayload,
    partner_code: userProfile.partner_code,
    BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
    NHANSU_ID: userProfile.NHANSU_ID,
  };

  function parseAndAssignField(payloadRq, field) {
    if (payloadRq[field]?.includes("{")) {
      HLog(`obj-------isPhuongXa`, JSON.parse(payloadRq[field]));
      return {
        ...payloadRq,
        ...JSON.parse(payloadRq[field]),
      };
    } else {
      delete payloadRq[field];
      return payloadRq;
    }
  }

  payloadRq = parseAndAssignField(payloadRq, "TEN_PHUONG_XA");
  payloadRq = parseAndAssignField(payloadRq, "TEN_QUAN_HUYEN");
  payloadRq = parseAndAssignField(payloadRq, "TEN_TINH_THANH");

  yield put(setFormLoading(true));

  // const isDup = yield call(handleCheckDuplicateInfo,payloadRq, form, duplicateInfoRef);
  // if(isDup) return false;

  // console.log("payloadRq.NGAY_SINHpayloadRq.NGAY_SINH",payloadRq.NGAY_SINH);
  try {
    yield common_post(apis.tiep_don_sua_thong_tin_benh_nhan, {
      ...payloadRq,
      ID: pushedPayload.BENH_NHAN_ID,
      NGAY_SINH: payloadRq.NGAY_SINH.length === 8 ? moment(payloadRq.NGAY_SINH, "DDMMYYYY").format("YYYYMMDD") : payloadRq.NGAY_SINH,
    });
    const updateDangKyGoi = yield common_post(apis.sua_dk_goi_dich_vu, {
      ...payloadRq,
    });

    if (updateDangKyGoi.status === "OK") {
      yield put(editRegistrationSuccess())
      yield callback();
      return
    }

    HLog(updateDangKyGoi);
    notification.error({
      placement: "bottomLeft",
      message: i18n.t(languageKeys.cap_nhat_that_bai),
    });
  } catch (error) {
    HLog(error);
    notification.error({
      placement: "bottomLeft",
      message: i18n.t(languageKeys.cap_nhat_that_bai),
    });
  } finally {
    yield put(setFormLoading(false));
  }
}

export function* handlePackageSubscriptionList({ payload }) {
  const userProfile = yield select((state) => state.auth.user);
  const payloadRq = {
    ...payload,
    partner_code: userProfile.partner_code,
    BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
  };
  try {
    let res = yield common_post(apis.lay_ds_dk_goi_dich_vu, payloadRq);
    const data = res?.result?.map((obj) => ({ ...obj, key: obj.ID }));

    if (res.status === "OK") {
      yield put(packageSubscriptionListSuccess({ page: payloadRq?.page || 1, limit: payloadRq?.limit || 15, data, total: res.total }));
      return
    }
    HLog(res);
    notification.error({
      placement: "bottomLeft",
      message: i18n.t(languageKeys.noti_lay_danh_sach_that_bai),
    });
  } catch (error) {
    HLog(error);
    notification.error({
      placement: "bottomLeft",
      message: i18n.t(languageKeys.noti_lay_danh_sach_that_bai),
    });
  }
}

export function* handleSubcribeBundles({ payload }) {

  const { bundle, patient, selectedPatient, lichKhamInfo, duplicateInfoRef, closeModal, onSelectPatient } = payload
  const userProfile = yield select((state) => state.auth.user);
  yield put(setFormLoading(true));

  let BENH_NHAN = {}
  try {
    if (!selectedPatient) {

      // Check trùng thông tin
      const checkResult = yield common_post(apis.checkTrungTTKH, {
        HO_TEN: patient?.TEN,
        NGAY_SINH: patient?.NGAY_SINH?.slice(-4),
        SO_DIEN_THOAI: patient?.SO_DIEN_THOAI
      })
      if (checkResult?.status === 'OK') {
        if (checkResult?.data?.length) {
          const data = checkResult.data[0];
          if (!patient.BENH_NHAN_ID) {
            duplicateInfoRef?.current?.open({ ...data, BENH_NHAN_ID: data?.ID })
            return false;
          }
        }
      }

      // Thêm mới bệnh nhân
      const patientData = yield formatPatientData(patient, false);
      const NGUON_KHACH_ID = patient?.DM_NGUON_KHACH_ID ? JSON.parse(patient.DM_NGUON_KHACH_ID)?.ID : null

      const res = yield common_post(apis.tiep_don_tao_benh_nhan, {
        ...patientData,
        TIEP_TAN_ID: userProfile.NHANSU_ID,
        NGUON_KHACH_ID
      });
      if (!!res) {
        BENH_NHAN = { ...patientData, BENH_NHAN_ID: res.ID, MA_BENH_NHAN: res.MA_BENH_NHAN, NGUON_KHACH_ID }
        onSelectPatient(BENH_NHAN)
      } else {
        return notification.error({ placement: "bottomLeft", message: i18n.t(languageKeys.noti_tao_moi_benh_nhan_that_bai) });
      }
    } else {
      BENH_NHAN = _.omit(selectedPatient, ["GOI_DV_ID", "IS_ACTIVE", "NGAY_KICH_HOAT", "NGAY_KET_THUC"])
    }

    // Đăng ký gói
    const res = yield common_post(apis.dk_su_dung_nhieu_goi, {
      DATA: bundle.map(item => ({
        ...item,
        ...BENH_NHAN,
        BENH_NHAN_ID: BENH_NHAN.ID || BENH_NHAN.BENH_NHAN_ID,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID
      }))
    })
    if (res) {
      if (res.status === 'OK') {
        if (patient) {
          yield put(TiepDonActions.doFetchDsGoiDvBenhNhan({ selectedPatient: BENH_NHAN, lichKhamInfo, isGetAll: true }))
        }
        closeModal()
        return notification.success({ placement: "bottomLeft", message: i18n.t(languageKeys.dk_goi_thanh_cong) });
      } else {
        return notification.error({ placement: "bottomLeft", message: i18n.t(languageKeys.thao_tac_that_bai) });
      }
    } else {
      return notification.error({ placement: "bottomLeft", message: i18n.t(languageKeys.app_loi_ket_noi) });
    }

  } catch (error) {
    HLog(error);
    notification.error({
      placement: "bottomLeft",
      message: i18n.t(languageKeys.noti_lay_danh_sach_that_bai),
    });
  } finally {
    yield put(setFormLoading(false));
  }
}